import React from "react";
import "../pages/mystyles.scss";
import Header from "../../../../components/header/header";
import Home from "../../../../components/home/home";
import Footer from "../../../../components/footer/footer";
import { graphql } from "gatsby";

const IndexPage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  return (
    <div className="layout">
      <Header
        category={data?.cms?.categories}
        domain={data?.cms?.domain}
        allPosts={allPosts}
        engine={options}
      />
      <Home data={data} />
      <Footer
        pages={data?.cms?.pages}
        domain={data?.cms?.domain}
        email={"editor@theartistictrap.com"}
      />
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query home {
    cms {
      domain: CMS_Domain(id: "3") {
        id
        logo
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "3") {
        id
        image
        name
        slug
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: 3) {
        title
        image
        id
        slug
        is_feature_post
        categories {
          id
          name
        }
      }
      recent_story: CMS_DomainWiseStories(domain_id: "3") {
        id
        image
        pub_date
        slug
        sub_title
        title
        content
        is_feature_post
        categories {
          id
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;

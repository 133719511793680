import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Sidebarone = ({ data }) => {
  const result = data.filter(checkImage);

  function checkImage(element) {
    return element.image;
  }

  const list = result ? [result[12], result[13]] : [];

  return (
    <div className="tile is-ancestor">
      <div className="tile is-vertical">
        {list.map((element) => {
          return (
            <div className="tile hfs-1">
              {" "}
              <Link to={`/${element?.slug}`}>
                <article
                  className="tile is-child hfs-1"
                  style={{
                    backgroundImage: `url(${element?.image})`,
                  }}
                >
                  <div className="hfs-details">
                    <div className="title">
                      {element?.title}
                      <div className="category">
                        <span>{element?.categories[0]?.name}</span>
                      </div>
                    </div>
                  </div>
                </article>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Sidebarone;

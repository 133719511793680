import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Hero = ({ data }) => {
  const hiro = [];

  const result = data.filter(checkImage);

  function checkImage(element) {
    return element.image;
  }

  // let list = data[data.length - 1];
  // hiro.push(list);

  for (let index = 0; index < 5; index++) {
    const element = data[index];
    hiro.push(element);
  }

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <div className="tile is-ancestor m-0">
      {/* <pre>{JSON.stringify({ data }, null, 2)}</pre> */}
      <div className="tile is-parent is-6 hf-1">
        <article
          className="tile is-child"
          style={{
            backgroundImage: `url(${addStr(result[0]?.image, 65, "576x450_")})`,
          }}
        >
          <Link to={`/${result[0]?.slug}`}>
            <div className="hf-details">
              <div className="category">
                <span>{result[0]?.categories[0]?.name}</span>
              </div>
              <div className="title">{result[0]?.title}</div>
            </div>
          </Link>
        </article>
      </div>
      <div className="tile is-vertical">
        <div className="tile is-parent hf-2">
          <article
            className="tile is-child"
            style={{
              backgroundImage: `url(${result[1]?.image})`,
            }}
          >
            <Link to={`/${result[1]?.slug}`}>
              <div className="hf-details">
                <div className="category">
                  <span>{result[1]?.categories[0]?.name}</span>
                </div>
                <div className="title med">{result[1]?.title}</div>
              </div>
            </Link>
          </article>
        </div>
        <div className="tile">
          <div className="tile is-parent is-vertical hf-3">
            <article
              className="tile is-child"
              style={{
                backgroundImage: `url(${result[2]?.image})`,
              }}
            >
              <Link to={`/${result[2]?.slug}`}>
                <div className="hf-details">
                  <div className="category">
                    <span>{result[2]?.categories[0]?.name}</span>
                  </div>
                  <div className="title sem">{result[2]?.title}</div>
                </div>
              </Link>
            </article>
          </div>
          <div className="tile is-parent hf-3">
            <article
              className="tile is-child"
              style={{
                backgroundImage: `url(${result[3]?.image})`,
              }}
            >
              <Link to={`/${result[3]?.slug}`}>
                <div className="hf-details">
                  <div className="category">
                    <span>{result[3]?.categories[0]?.name}</span>
                  </div>
                  <div className="title sem">{result[3]?.title}</div>
                </div>
              </Link>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
